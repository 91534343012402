header {
  position: fixed;
  width: 100%;
  height: 90px;
  padding: 10px 30px;
  background-color: #222;
  @media(max-width: 992px) {
    height: 50px;
    padding: 10px 0;
  }
  .header1 {
    display: flex;
    height: 100%;
    align-items: center;
    .logo-href {
      img {
        width: 180px;
        height: 100%;
        @media(max-width:992px) {
          height: 50px;
        }
      }
    }
  }
}

footer {
  background-color: #000;
  @media(max-width:992px) {
    margin-bottom: 100px;
  }
  .footer-1 {
    width: 100%;
    height: 100%;
    p {
      color: #fff;
      font-size: 16px;
      padding-bottom: 5px;
    }
  }
}